// =============== Variables ===============

// --------------- Defaults ---------------

$default-color: #bebebe;
$default-bg: #222;
$default-dark-bg: #131313;
$default-header-bg: #181818;
$default-border: #404040;

$item-color: (
  "primary": #fff,
  "secondary": #fff,
  "success": #fff,
  "danger": #fff,
  "warning": #212529,
  "info": #fff,
  "light": #212529,
  "dark": #fff,
);

$item-bg: (
  "primary": #007bff,
  "secondary": #6c757d,
  "success": #28a745,
  "danger": #dc3545,
  "warning": #ffc107,
  "info": #17a2b8,
  "light": #6c757d,
  "dark": #343a40,
);

$item-hover: (
  "primary": #0069d9,
  "secondary": #5a6268,
  "success": #218838,
  "danger": #c82333,
  "warning": #e0a800,
  "info": #138496,
  "light": #e2e6ea,
  "dark": #23272b,
);

$color-lighten: 10;

// --------------- Customized ---------------

$color: $default-color;
$bg: $default-bg;

$window-color: $default-color;
$window-bg: $default-bg;
$window-header-color: $default-color;
$window-header-bg: $default-header-bg;
$window-border: $default-border;

$card-color: $default-color;
$card-bg: $default-bg;
$card-header-color: $default-color;
$card-header-bg: $default-header-bg;
$card-hover-bg: $default-bg;
$card-border: $default-border;

$form-color: $default-color;
$form-placeholder-color: #4c4c4c;
$form-bg: #090909;//darken($default-bg, 6);
$form-disabled-bg: lighten($form-bg, 5);
$form-hover-bg: #242424;
$form-border: $form-bg;

$form-addon-color: $default-color;
$form-addon-bg: $default-header-bg;

@mixin darkmode {
  color: $color;
  background-color: $bg;

  .bg-darkmode-dark {
    background-color: #343a40 !important
  }

  .bg-darkmode-light {
    background-color: #f8f9fa !important;
  }

  .bg-darkmode-black {
    background-color: $bg !important;
  }

  // --------------- Typography ---------------

  pre {
    color: $color;
  }

  hr {
    border-top-color: $default-border;
  }

  // --------------- Images ---------------

  img.icon,
  svg.icon {
    filter: invert(1);
  }

  // --------------- Tables ---------------

  .table {
    color: $color;

    th, td {
      border-top-color: $default-border;
    }

    thead th, tbody + tbody {
      border-bottom-color: $default-border;
    }

    tr {
      background-color: $default-bg;
    }

  }

  .table-hover tbody tr:hover {
    color: $color;
    background-color: $default-dark-bg;
  }

  .table-bordered {
    border-color: $default-border;

    th, td {
      border-color: $default-border;
    }
  }

  // --------------- List Groups ---------------

  .list-group-item {
    color: $card-color;
    background-color: $card-bg;
    border-color: $card-border;

    &.list-group-item-action:focus,
    &.list-group-item-action:hover {
      background-color: $card-hover-bg;
    }
  }

  .list-group-item.active {
    &, &:focus, &:hover {
      color: map_get($item-color, "primary");
      background-color: map_get($item-bg, "primary");
      border-color: map_get($item-bg, "primary");
    }
  }

  @each $name in map_keys($item-color) {
    .list-group-item-#{$name} {
      color: map_get($item-color, $name);
      background-color: map_get($item-bg, $name);
      border-color: map_get($item-bg, $name);

      &.list-group-item-action:focus,
      &.list-group-item-action:hover {
        color: map_get($item-color, $name);
        background-color: map_get($item-hover, $name);
      }
    }
  }

  // --------------- Buttons ---------------

  .dropdown-menu {
    background-color: $form-bg;
    border-color: $default-border;
  }

  .dropdown-item:not(:disabled):not(.disabled) {
    color: $form-color;
  }

  .dropdown-item:focus,
  .dropdown-item:hover {
    //background-color: $form-hover-bg;
    color: #FFF!important;
  }

  .dropdown-divider {
    border-top-color: $default-border;
  }

  // --------------- Jumbotron ---------------

  .jumbotron {
    color: $card-color;
    background-color: $card-bg;
    border-color: $card-border;
  }

  // --------------- Cards ---------------

  .card {
    color: $card-color;
    background-color: $card-bg;
    border-color: $card-border;

    //box-shadow: 0 0 10px #000!important;
  }

  .card-header,
  .card-footer {
    color: $card-header-color;
    background-color: $card-header-bg;
    border-top-color: $card-border;
    border-bottom-color: $card-border;
  }

  // --------------- Forms ---------------

  .form-control {
    color: $form-color;
    background-color: $form-bg;
    border-color: $form-border;
    &::placeholder {
      color: $form-placeholder-color!important;
    }
    &:disabled, &[readonly] {
      background-color: $form-disabled-bg;
      opacity: 1;
    }
  }

  .form-control-plaintext {
    color: $form-color;
  }

  .custom-select {
    color: $form-color;
    background-color: $form-bg;
    border-color: $form-border;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23ffffff' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e");
  }

  .custom-file-label {
    color: $form-color;
    background-color: $form-bg;
    border-color: $form-border;

    &:after {
      color: $form-addon-color;
      background-color: $form-addon-bg;
    }
  }

  .input-group-text {
    color: $form-addon-color;
    background-color: $form-addon-bg;
    border-color: $form-border;
  }

  .page-item .page-link {
    border-color: $form-border;
  }

  .page-item.disabled .page-link {
    background-color: $form-bg;
    color: $form-color;
  }

  .page-item:not(.active) .page-link {
    background-color: $form-bg;
  }

  .page-item:not(.active) .page-link:hover {
    background-color: $form-hover-bg;
  }

  // --------------- Navs ---------------

  .breadcrumb {
    background-color: $form-bg;
    border-color: $default-border;
  }

  .nav-tabs {
    border-bottom-color: $default-border;

    .nav-link:focus,
    .nav-link:hover {
      background-color: $form-hover-bg;
      border-color: $default-border;
    }

    .nav-item.show .nav-link,
    .nav-link.active {
      color: $color;
      background-color: $bg;
      border-color: $default-border;
      border-bottom-color: $bg;
    }
  }

  .nav-tabs.card-header-tabs {
    .nav-item.show .nav-link,
    .nav-link.active {
      background-color: $card-bg;
      border-bottom-color: $card-bg;
    }
  }

  // --------------- Popovers ---------------

  .popover {
    background-color: $window-bg;
    border-color: $window-border;
  }

  .popover-body {
    color: $color;
  }

  .popover-header {
    background-color: $window-header-bg;
    border-bottom-color: $window-border;
  }

  @each $pos in (top, right, bottom, left) {
    .bs-popover-#{$pos},
    .bs-popover-auto[x-placement^="#{$pos}"] {
      & > .arrow::before {
        border-#{$pos}-color: $window-border;
      }

      & > .arrow::after {
        border-#{$pos}-color: $window-bg;
      }
    }
  }

  // --------------- Progress Bars --------------- */

  .progress {
    background-color: $form-bg;
    border-color: $default-border;
  }

  // --------------- Modals ---------------

  .close {
    color: $color;
    opacity: 1;
  }

  .modal-header,
  .modal-footer {
    color: $window-header-color;
    background-color: $window-header-bg;
    border-bottom-color: $window-border;
    border-top-color: $window-border;
  }

  .modal-content {
    color: $window-color;
    background-color: $window-bg;
    border-color: $card-border;
  }

  // Custom App Overrides

  &:after {
    background-color: $default-dark-bg!important;
  }

  .app-wrapper {
    background-color: $default-dark-bg!important;
  }

  .content-container, .content-bg {
    background-color: $default-bg !important;
  }

  .content-container {
    box-shadow: 0 0 8px rgba(0, 0, 0, 1);
  }

  .tab-container {
    border-color: $default-border!important;
  }

  ngxh6-form-actions.sticky, .form-actions.sticky {
    background-color: $default-bg;
    border-color: $default-border!important;
  }

  h1, h2, h3, h4, h5, h6 {
    color: $color;
  }

  label {
    color: darken($color, 20%);
  }

  input[type="checkbox"], input[type="radio"] {
    background-color: $form-bg!important;
    border-color: $form-border;
  }

  input[type="radio"]:after {
    border-color: $form-bg!important;
  }

  .btn-gray {
    background-color: $default-border;
    &:hover {
      background-color: darken($default-border, 10%);
    }
  }

  .btn-outline-gray {
    background-color: darken($default-border, 17%);;
    border-color: $default-border;
    color: lighten($default-border, 20%);
    &:hover {
      color: #FFF;
      background-color: darken($default-border, 10%);
    }
  }

  .dropdown-menu, ng-dropdown-panel {
    box-shadow: 0 0 10px rgb(0 0 0);
  }

  ng-dropdown-panel {
    //background-color: $default-bg;
    border: $default-border 1px solid!important;

    .ng-dropdown-header, .header-container, .ng-dropdown-footer, .ng-dropdown-panel-items {
      background-color: $form-bg;
      border-color: $default-border !important;
    }
    .small-search {
      color: $default-color;
      background-color: unset !important;
    }


    @include media-breakpoint-down(lg) {
      border: none!important;
      box-shadow: none!important;

      .ng-dropdown-header {
        border: $default-border 1px solid!important;
        border-bottom: none!important;
      }
      .ng-dropdown-panel-items {
        border-left: $default-border 1px solid!important;
        border-right: $default-border 1px solid!important;
      }
      .ng-dropdown-footer {
        border: $default-border 1px solid!important;
      }
    }

  }

  ng-select {
    .ng-placeholder {
      color: $form-placeholder-color;
    }

    &.ng-select-disabled {
      background-color: $form-disabled-bg;
    }

    .ng-input {
      input {
        color: $default-color!important;
      }
      color: $default-color!important;
    }
  }

  .sidebar {
    .logo {
      //background-color: $secondary!important;
      img {
        filter: saturate(0) contrast(100%) brightness(100);
      }
    }
    .sidebar-nav {
      background-color: $default-bg!important;

      @include media-breakpoint-up(md) {
        box-shadow: 0 0 8px #000!important;
      }

      .nav-link {
        color: $default-color !important;

        &:hover, &.active {
          background-color: $default-dark-bg!important;
        }

        &.active {
          color: $tertiary !important;
        }

      }
    }
  }

  .custom-calendar {
    .inner {
      background-color: $form-bg;
      @include media-breakpoint-down(lg) {
        border-radius: 3px;
        border: $default-border 1px solid;
      }
      .top{
        border-bottom: $default-border 1px solid;
      }
      .actions {
        border-top: $default-border 1px solid;
      }
    }

    ngb-datepicker {
      .ngb-dp-weekdays, .ngb-dp-header {
        background-color: lighten($form-bg, 10)!important;
      }

      .ngb-dp-today > div{
        border: 1px solid $default-border;
      }

      .btn-light {
        color: #e0e0e0;
        &:hover {
          color: #333;
        }
      }
    }
  }


  quill-editor .ql-toolbar, quill-editor .ql-container {
    border-color: $form-border!important;
    background-color: $form-bg!important;
  }

  .ql-editor {
    h1,h2,h3,h4,h5,h6, p, ol, ul, span, li{
      color: $default-color !important;
    }
    &::before {
      color: $form-placeholder-color!important;
    }
  }

  .note, .version {
    .header {
      background-color: $default-dark-bg;
    }
  }

  sf-form-select-imagegrid {
    .option {
      border-color: $form-border;
    }
  }
  sf-form-involved {
    .female h5, .female h6 {
      color: pink!important;
    }

    .male h5, .male h6 {
      color: lightblue!important;
    }

  }

}

@media (prefers-color-scheme: dark) {
  [data-theme=auto] {
    @include darkmode;
  }
}

[data-theme=dark] {
  @include darkmode;
}
