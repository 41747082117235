@import '../../src/scss/bootstrap_base';

@media print {
  app-filter, .sidebar, .nav-tabs, app-navbar, ngxh6-requests {
    display: none!important;
  }

  .content-container {
    padding: 0!important;
    box-shadow: none!important;
  }

  .container-fluid {
    padding-top: 0!important;
    padding-bottom: 0!important;
  }

  .app-wrapper {
    margin-top: 0!important;
    min-height: auto!important;
  }

  body::before {
    display: none;
  }

  @page
  {
    size: auto;   /* auto is the initial value */

    /* this affects the margin in the printer settings */
    margin: 25mm 25mm 25mm 25mm;
  }

  body
  {
    /* this affects the margin on the content before sending to printer */
    margin: 0px;
  }

}

