//$primary: #C4161C;
$primary: #CC6A2D;
//$secondary: #C4161C;
$secondary: #490000;
$tertiary: #C4161C;

$success: #63b160;
$danger: #fa6557;
$warning: #efa755;

/* these are non- bootstrap variables. f69346*/
$content-bg: #FFF;
$list-bg: #FFF;
$navbar-bg: #490000;

$sidebar-bg: #fff;
$sidebar-highlight: #490000;
$sidebar-selected: $tertiary;
$sidebar-selected-bg: #efefef;
$sidebar-menu-btn: #000;
/* /these are non- bootstrap variables. */

$body-bg: #efefef;
$body-color: #232832;
$input-placeholder-color: #cbcbcb;
$headings-color: #1f232b;

$form-label-color: #586071;
$form-label-font-weight: 500;
$form-label-font-size: 14px;
$form-label-margin-bottom: 0.4rem;

$input-bg: #fbfbfb;
$input-border-color: #b8b8b8;
$input-focus-bg: #FFF;

$accordion-bg: $content-bg;
$card-bg: $content-bg;
$list-bg: $content-bg;
$list-group-bg: $content-bg;

$dropdown-link-hover-bg: $primary;
$dropdown-link-active-bg: darken($primary, 10%);
$dropdown-link-hover-color: #fff;


$border-radius:    3px;
$border-radius-lg: 3px;
$border-radius-sm: 3px;

$input-padding-x: 0.5rem;
$input-padding-y: 0.5rem;

$input-btn-padding-x: 0.7rem;
$input-btn-padding-y: 0.5rem;

$input-font-size: 0.95rem;
$input-btn-font-size: 0.95rem;

$font-family-base: 'Roboto';
$font-family-sans-serif: 'Roboto';
$headings-font-family: 'Roboto';

$btn-font-size-sm: 0.75rem;
$btn-padding-x-sm: 10px;
$btn-padding-y-sm: 2px;
$btn-hover-bg-shade-amount: 100;

$enable-responsive-font-sizes: true;

$table-hover-bg: #f6f6f6;

@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/maps';
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/utilities';
@import '../../node_modules/bootstrap/scss/root';

