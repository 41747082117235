@import '../../../src/scss/bootstrap_base';
@import '../../../src/scss/helpers/_root';

.sidebar-dim {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: #292929;
  opacity: 0.4;

  @include media-breakpoint-up(lg){
    display: none!important;
  }
}

.sidebar{
  height: auto;
  position: relative;
  overflow: hidden;

  flex: 0 0 0;
  width: 0;

  .logo {
    padding: 20px;
    img {
      max-width: 180px;
      width: 100%;
    }
  }

  @include media-breakpoint-up(sm){
    flex: 0 0 68px;
    width: 68px;
  }
  @include media-breakpoint-up(lg){
    flex: 0 0 250px;
    width: 250px;
    transition: all .1s linear;
  }

  order: -1;
  display: flex;
  flex-direction: column;
  padding: 0;

  .sidebar-nav{
    @include media-breakpoint-up(sm) {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
    }

    color: #292929;
    background: $sidebar-bg;

    transition: all .1s linear;
    transform: translateZ(0);

    width: 250px;
    position: fixed;
    height: calc(100vh - var(--navbar-height-total));
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    z-index: 100;
  }

  &.offline .sidebar-nav {
    height: calc(100vh - var(--navbar-height-total) - 20px);
  }

  .nav{
    width: 100%;
    flex-direction: column;
    min-height: 100%;
    padding: 0;
    border-right: 1px solid $border-color;
  }

  .nav-title{
    padding: 0.75rem 1rem;
    font-size: 80%;
    font-weight: 500;
    //text-transform: uppercase;
  }

  .nav-item{
    position: relative;
    margin: 0;
    max-width: 100%;
  }

  .nav-link{
    display: block;
    padding: 0.75rem 1rem;
    color: $sidebar-highlight;
    text-decoration: none;
    background: transparent;
    min-height:50px;
    cursor: pointer;

    .text{
      line-height: 30px;
      transition: all .1s linear;
      transform: translateZ(0);
      margin-left: 50px;
      overflow: hidden;
      white-space: nowrap;
      font-weight: 500;
      //text-transform: uppercase;
    }

    .fa {
      transition: all .1s linear;
      top: 10px;
      left: 14px;
      position: absolute;
      min-width: 20px;
      font-size: 2rem;
      transform-origin: center;
      transform: scale(0.6);
    }

    &:hover{
      background: darken($sidebar-bg, 4);
    }

    &.active{
      background-color: $sidebar-selected-bg;
      color: $sidebar-selected;
    }

  }

  .logo {
    transition: 150ms linear opacity!important;
  }


  @include media-breakpoint-up(lg){
    &.toggle-large {
      flex: 0 0 68px;
      width: 68px;

      .sidebar-nav {
        width: 68px;
      }

      .logo{
        opacity: 0;
      }

      .nav-link {
        .text {
          opacity: 0;
        }

        .fa {
          transform: none;
        }

        .badge {
          display: none;
        }
      }

      .menu-toggle .nav-link .fa {
        transform: rotate(180deg);
      }
    }
  }

  @include media-breakpoint-between(sm, xl) {
    &:not(.toggle) {
      flex: 0 0 68px;
      width: 68px;

      .sidebar-nav {
        width: 68px;
      }

      .logo{
        opacity: 0;
      }

      .nav-link {
        .text {
          opacity: 0;
        }

        .fa {
          transform: none;
        }

        .badge {
          display: none;
        }
      }

      .menu-toggle .nav-link .fa {
        transform: rotate(180deg);
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .sm-menu-button{
      color: $sidebar-menu-btn;
      position: fixed;
      top: 7px;
      left: 5px;
      z-index: 1050;
      font-size: 1.4rem;
      box-shadow: none!important;
    }

    .sidebar-nav{
      margin-left: -250px;
    }

    &.toggle{
      .sidebar-nav{
        margin-left: 0;
      }
    }

  }
}
